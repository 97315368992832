<template>
  <div class="card full-page">
    <div v-show="!isLoading" class="card-body inner-scroll">
      <div v-if="stripeSubscription" class="card-content">
        <h2>{{ $t('Manage subscription') }}</h2>
        <p class="subscription-help">{{ $t('sub_help') }}</p>
        <div class="subscription-info">
          <div>
            <h3>{{ $t('Subscription details') }}</h3>
            <div class="details-table">
              <span class="key">{{ $t('Plan') }}</span>
              <span>{{ $t($store.state.auth.user.plan) }}</span>
              <span class="key">{{ $t('Status') }}</span>
              <span>{{ $t(stripeSubscription.status) }}</span>
              <span class="key">{{ $t('Payment Interval') }}</span>
              <span>{{
                stripeSubscription.plan.interval === 'month' ? $t('Monthly') : $t('yearly')
              }}</span>
            </div>
            <button class="btn btn-danger" @click="showCancelModal = true">
              {{ $t('Cancel my subscription') }}
            </button>
          </div>
          <div>
            <h3>{{ $t('Payment details') }}</h3>
            <div v-if="paymentMethods.length > 0">
              <p>{{ $t('These are your payment methods.') }}</p>
              <div class="payment-methods">
                <PaymentMethod
                  v-for="paymentMethod in paymentMethods"
                  :key="paymentMethod.id"
                  :paymentMethod="paymentMethod"
                />
              </div>
            </div>
            <div v-if="paymentMethods.length === 0 && !subscriptionError" class="stripe-form">
              <p>{{ $t('You have no payment methods please add one below.') }}</p>
              <div id="payment-element"></div>
              <p v-if="stripeError" class="stripe-error" role="alert">
                {{ stripeError }}
              </p>
              <p
                v-html="
                  $t('add_payment_method_with_trial', {
                    amount: paymentPlans[`${$store.state.auth.user.plan}_yearly`].price,
                    period: `${$t('year')}`,
                    trialEndDate,
                  })
                "
              ></p>
              <b-button
                variant="primary"
                class="add-payment-method-button"
                :disabled="isAddingPaymentMethod"
                @click="addPaymentMethod"
              >
                <template v-if="isAddingPaymentMethod">
                  <b-spinner small />
                </template>
                <template v-else>
                  {{ $t('Save') }}
                </template>
              </b-button>
            </div>
            <b-alert show variant="warning" v-if="subscriptionError">{{
              subscriptionError
            }}</b-alert>
          </div>
          <div>
            <h3>{{ $t('Billing details') }}</h3>
            <div id="address-element"></div>
            <TaxIDField v-model="taxID" />
            <b-button
              variant="primary"
              @click="updateBillingDetails"
              :disabled="isUpdatingBillingDetails"
              >{{ $t('Update') }}</b-button
            >
          </div>
          <div v-if="hasPaymentsEnabled">
            <hr />
            <h3>Stripe connect</h3>
            <div class="details-table">
              <span class="key">{{ $t('Payouts enabled') }}</span>
              <span>{{ hasStripeConnectEnabled ? $t('Yes') : $t('No') }}</span>
              <span class="key">{{ $t('Payout Interval') }}</span>
              <span>{{ $t('Weekly') }}</span>
            </div>
            <div class="form-group">
              <b-link
                v-if="hasStripeConnectEnabled"
                class="btn btn-primary mr-5"
                :href="stripeDashboardURL"
                target="_blank"
                >{{ $t('Stripe dashboard') }}</b-link
              >
              <router-link v-else class="btn btn-secondary mr-5" to="/payments">{{
                $t('Setup payments')
              }}</router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <h2>{{ $t('We could not find your subscription') }}</h2>
        <h3>{{ $t('Get in touch with us to fix this issue') }}</h3>
      </div>
    </div>
    <Modal
      id="cancel-subscription-modal"
      :text="$t('confirm_cancel_subscription')"
      :ok-button="$t('Yes')"
      :cancel-button="$t('No')"
      :show="showCancelModal"
      @result="cancelSubscription"
      @hidden="showCancelModal = false"
    />
  </div>
</template>

<script>
import { BLink, BButton, BAlert, BSpinner } from 'bootstrap-vue'
import { loadStripe } from '@stripe/stripe-js/pure'

import { paymentPlans } from '@/core/config/payments'
import TaxIDField from '@/components/TaxIDField'
import PaymentMethod from '@/components/PaymentMethod'
import Modal from '@/components/Modal'
import userCan from '@/libs/user-can'
import PaymentService from '@/services/PaymentService'
import StripeService from '@/services/StripeService'

export default {
  name: 'ManageSubscription',
  components: {
    BLink,
    BButton,
    BAlert,
    BSpinner,
    TaxIDField,
    PaymentMethod,
    Modal,
  },
  data() {
    return {
      PaymentService: new PaymentService(),
      StripeService: new StripeService(),
      paymentMethods: [],
      stripeSubscription: null,
      stripeClient: undefined,
      stripeElements: undefined,
      stripeError: '',
      stripeDashboardURL: `https://billing.stripe.com/p/login/${process.env.VUE_APP_STRIPE_DASHBOARD_ID}`,
      subscriptionError: '',
      taxID: { tax_id_type: '', tax_id: '' },
      hasStripeConnectEnabled: false,
      isLoading: true,
      isAddingPaymentMethod: false,
      isUpdatingBillingDetails: false,
      showCancelModal: false,
      paymentPlans: paymentPlans,
    }
  },
  computed: {
    hasPaymentsEnabled() {
      return userCan(this.$store.state.auth.user.plan, 'payments')
    },
    trialEndDate() {
      if (!this.$store.state.auth.user.trial_ends_at) return 'N/A'
      return new Date(this.$store.state.auth.user.trial_ends_at).toLocaleDateString(
        this.$i18n.locale,
        {
          weekday: 'long',
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }
      )
    },
  },
  methods: {
    async cancelSubscription(confirm) {
      if (confirm) {
        try {
          await this.PaymentService.unsubscribe()
          await this.$store.dispatch('auth/fetchUserData')
          this.$router.replace('/overview')
        } catch (error) {
          console.error(err)
        }
      }
    },
    async updateBillingDetails() {
      this.isUpdatingBillingDetails = true
      const addressElement = this.stripeElements.getElement('address')
      const { complete, value } = await addressElement.getValue()

      if (complete && this.taxID.tax_id_type && this.taxID.tax_id) {
        await this.StripeService.updateBillingDetails({
          name: value.name,
          ...value.address,
          ...this.taxID,
        })
      } else {
        // Error
      }
      this.isUpdatingBillingDetails = false
    },
    async loadData() {
      try {
        const stripeData = await this.StripeService.getStripeData()

        this.hasStripeConnectEnabled = stripeData.connect?.payouts_enabled
        this.stripeSubscription = stripeData.current_subscription
        this.paymentMethods = stripeData.payment_methods.data

        if (stripeData.customer.tax_ids.data.length > 0)
          this.taxID = {
            tax_id_type: stripeData.customer.tax_ids.data[0].type,
            tax_id: stripeData.customer.tax_ids.data[0].value,
          }

        const clientSecret = stripeData.current_subscription?.pending_setup_intent?.client_secret
        this.stripeClient = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY)
        this.stripeElements = this.stripeClient.elements({
          clientSecret,
          fonts: [
            {
              cssSrc:
                'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap',
            },
          ],
          locale: this.$i18n.locale,
          appearance: {
            theme: 'stripe',
            variables: {
              fontFamily: 'Poppins, sans-serif',
              fontSizeBase: '14px',
              colorPrimary: '#435993',
              colorBackground: '#fff',
              colorText: '#0E1A1A',
              colorDanger: '#FD8389',
              borderRadius: '4px',
            },
          },
        })
        const addressElement = this.stripeElements.create('address', {
          mode: 'billing',
          allowedCountries: ['es'],
          defaultValues: {
            name: stripeData.customer.name || this.$store.state.auth.user.business_name,
            address: stripeData.customer.address || {},
          },
          display: { name: 'organization' },
        })
        addressElement.mount('#address-element')

        if (stripeData.payment_methods.data.length === 0 && clientSecret) {
          const paymentElement = this.stripeElements.create('payment', {
            wallets: { applePay: 'never', googlePay: 'never' },
          })
          paymentElement.mount('#payment-element')
        } else if (stripeData.payment_methods.data.length === 0 && !clientSecret) {
          console.log('error')
          this.subscriptionError = this.$t('subscription_error')
        }
      } catch (error) {
        console.error(error)
      }
      this.isLoading = false
    },
    async addPaymentMethod() {
      this.isAddingPaymentMethod = true
      try {
        const stripeResponse = await this.stripeClient.confirmSetup({
          elements: this.stripeElements,
          confirmParams: {
            return_url: `${process.env.VUE_APP_BASE_URL}/manage-subscription`,
          },
        })

        if (stripeResponse.error) {
          this.stripeError = stripePaymentResult.error.message
          console.error('error', stripePaymentResult.error.message)
        }
        this.isAddingPaymentMethod = false
      } catch (error) {
        this.isAddingPaymentMethod = false

        console.error(error)
      }
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>
.stripe-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.stripe-error {
  margin-top: 16px;
  color: #fb8389;
}

.add-payment-method-button {
  width: 100%;
}

.payment-methods {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
}

.subscription-help {
  font-size: 16px;
  margin-bottom: 2.5rem;
}

.subscription-info {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.details-table {
  padding: 8px;
  display: grid;
  grid-template-columns: minmax(0, 160px) minmax(0, 1fr);
  column-gap: 16px;
  row-gap: 8px;
  margin-bottom: 32px;
}
.key {
  font-weight: bold;
  display: inline-block;
}

.form-group {
  margin-top: 2em;
}

@media screen and (min-width: 768px) {
  .add-payment-method-button {
    width: fit-content;
    min-width: 160px;
    margin: 0 auto 0 0;
  }
}
</style>
