import CrudService from '@/services/shared/http/CrudService'

export default class PaymentService extends CrudService {
  constructor(params = {}) {
    super('payments', params)
  }

  onboarding(slug, params) {
    return this.api.GET(this.endPoint + '/onboarding/' + slug, params)
  }

  unsubscribe() {
    return this.api.POST(this.endPoint + '/unsubscribe')
  }
}
