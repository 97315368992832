<template>
  <div class="">
    <b-modal v-model="showDialog" :centered="true" @hidden="hidden(false)" hide-footer>
      <b-container fluid>
        <template #modal-header />
        <b-row class="justify-content-center bg-white" style="background-color: #ffffff">
          <b-col cols="10">
            <p style="color: #0e1a1a" class="font-weight-bold text-lowercase">
              {{ $t(text) }}
            </p>
          </b-col>
        </b-row>
        <b-row class="justify-content-center">
          <BButton
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-2 mr-2"
            @click="submitModal(true)"
          >
            {{ $t(okButton) }}
          </BButton>
          <BButton
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="mb-2"
            @click="submitModal(false)"
          >
            {{ $t(cancelButton) }}
          </BButton>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, BContainer, BRow, BCol } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'Modal',
  components: {
    BButton,
    BContainer,
    BModal,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    text: {
      default: 'Are you sure you want to do this action ?',
      type: String,
    },
    okButton: {
      default: 'OK',
      type: String,
    },
    cancelButton: {
      default: 'Cancel',
      type: String,
    },
    goToPath: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      showDialog: this.show,
    }
  },
  watch: {
    show: {
      handler() {
        this.showDialog = this.show
      },
    },
  },
  mounted() {
    this.showDialog = false
  },
  methods: {
    submitModal(value) {
      this.showDialog = false
      this.$emit('result', value)
    },
    hidden(value) {
      this.showDialog = false
      this.$emit('hidden', value)
    },
  },
}
</script>

<style scoped>
p.text-lowercase::first-letter {
  text-transform: uppercase;
}
</style>
